import { useCallback } from "react";

import useAsyncState from "../AppShell/hooks/useAsyncState";

import useDeliveryLocationsStore from "./useDeliveryLocationsStore";
import useDeliveryLocationsClient from "./useDeliveryLocationsClient";

function useAsyncDeliveryLocationsStore() {
  const { isLoading, setIsLoading } = useAsyncState();
  const deliveryLocationsClient = useDeliveryLocationsClient();
  const deliveryLocationsStore = useDeliveryLocationsStore();
  const { setDeliveryLocations } = deliveryLocationsStore;

  const fetchDeliveryLocations = useCallback(async () => {
    setIsLoading(true);
    const orders = await deliveryLocationsClient.fetchDeliveryLocations();
    setDeliveryLocations(orders);
    setIsLoading(false);
  }, [deliveryLocationsClient, setIsLoading, setDeliveryLocations]);

  return {
    ...deliveryLocationsStore,
    fetchDeliveryLocations,
    isLoading,
  };
}

export default useAsyncDeliveryLocationsStore;
