import format from "date-fns/format";
import { IDeliveryWindow } from "../clients/DeliveryWindowsClient";

class DeliveryWindowFormatter {
  formatTime(deliveryWindow: IDeliveryWindow) {
    const fromHour = format(deliveryWindow.from, "HH:mm");
    const toHour = format(deliveryWindow.to, "HH:mm");

    return `${fromHour} - ${toHour}`;
  }

  formatDate(deliveryWindow: IDeliveryWindow) {
    return format(deliveryWindow.from, "MMM dd, yyyy");
  }
}

const deliveryWindowFormatter = new DeliveryWindowFormatter();

function useDeliveryWindowFormatter() {
  return deliveryWindowFormatter;
}

export default useDeliveryWindowFormatter;
