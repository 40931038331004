import { useCallback, useContext } from "react";
import { DeliveryWindowsStateContext } from "../context/deliveryWindows";
import { IDeliveryWindow } from "../clients/DeliveryWindowsClient";
import useDeliveryWindowsClient from "./useDeliveryWindowsClient";

function useDeliveryWindowsStore() {
  const deliveryWindowsClient = useDeliveryWindowsClient();
  const deliveryWindowsState = useContext(DeliveryWindowsStateContext);

  if (deliveryWindowsState === null) {
    throw new Error(
      "Delivery windows state not found. A context provider must be present in your app."
    );
  }

  const submitDeliveryWindows = useCallback(
    (deliveryWindows: IDeliveryWindow[]) =>
      deliveryWindowsClient.submit(deliveryWindows),
    [deliveryWindowsClient]
  );

  return {
    ...deliveryWindowsState,
    submitDeliveryWindows,
  };
}

export default useDeliveryWindowsStore;
