import { useCallback, useEffect, useMemo, useState } from "react";

import { User } from "./useAuthenticatedUser";
import useAuthenticationClient from "./useAuthenticationClient";

function useAuthentication() {
  const client = useAuthenticationClient();
  const [user, setUser] = useState<User | null>(client.getUser());
  const [error, setError] = useState(client.getError());

  const isAuthenticationRequired = useMemo(
    () => !error && !user,
    [error, user]
  );

  const handleErrorChange = useCallback(
    (error: boolean) => {
      setError(error);
    },
    [setError]
  );

  const handleUserChange = useCallback(
    (user: User | null) => {
      setUser(user);
    },
    [setUser]
  );

  const subscribers = useMemo(() => {
    return {
      "/error": [handleErrorChange],
      "/user": [handleUserChange],
    };
  }, [handleErrorChange, handleUserChange]);

  useEffect(() => {
    client.subscribe(subscribers);

    return () => {
      client.unsubscribe(subscribers);
    };
  }, [client, isAuthenticationRequired, subscribers]);

  return {
    error,
    isAuthenticationRequired,
    user,
  };
}

export default useAuthentication;
