import { useCallback, useContext } from "react";
import { IDelivery } from "../clients/DeliveriesClient";
import { DeliveriesStateContext } from "../context/deliveries";
import useDeliveriesClient from "./useDeliveriesClient";

function useDeliveriesStore() {
  const deliveriesClient = useDeliveriesClient();
  const deliveriesState = useContext(DeliveriesStateContext);

  if (deliveriesState === null) {
    throw new Error(
      "Deliveries state not found. A context provider must be present in your app."
    );
  }

  const submitDeliveries = useCallback(
    (deliveries: IDelivery[]) => deliveriesClient.submit(deliveries),
    [deliveriesClient]
  );

  return {
    ...deliveriesState,
    submitDeliveries,
  };
}

export default useDeliveriesStore;
