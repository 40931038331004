import { useCallback, useReducer } from "react";
import { IDelivery } from "../clients/DeliveriesClient";

const INITIAL_DELIVERIES_STATE: IDelivery[] = [];

const ADD_DELIVERY = "deliveries/add";

interface IDeliveriesStateAddAction {
  type: typeof ADD_DELIVERY;
  payload: {
    delivery: IDelivery;
  };
}

const CLEAR_DELIVERIES = "deliveries/clear";

interface IDeliveriesStateClearAction {
  type: typeof CLEAR_DELIVERIES;
}

const REMOVE_DELIVERY = "deliveries/remove";

interface IDeliveriesStateRemoveAction {
  type: typeof REMOVE_DELIVERY;
  payload: {
    delivery: IDelivery;
  };
}

const SAVE_DELIVERY = "deliveries/save";

interface IDeliveriesStateSaveAction {
  type: typeof SAVE_DELIVERY;
  payload: {
    delivery: IDelivery;
  };
}

const SET_DELIVERIES = "deliveries/set";

interface IDeliveriesStateSetAction {
  type: typeof SET_DELIVERIES;
  payload: {
    deliveries: IDelivery[];
  };
}

type IDeliveriesStateAction =
  | IDeliveriesStateAddAction
  | IDeliveriesStateClearAction
  | IDeliveriesStateRemoveAction
  | IDeliveriesStateSaveAction
  | IDeliveriesStateSetAction;

function addDelivery(state: IDelivery[], action: IDeliveriesStateAddAction) {
  const { delivery } = action.payload;

  return [...state, delivery];
}

function clearDeliveries() {
  return INITIAL_DELIVERIES_STATE;
}

function removeDelivery(
  state: IDelivery[],
  action: IDeliveriesStateRemoveAction
) {
  const { delivery } = action.payload;

  return state.filter((d) => d.id !== delivery.id);
}

function saveDelivery(state: IDelivery[], action: IDeliveriesStateSaveAction) {
  const { delivery } = action.payload;

  return state.map((d) => {
    if (d.id === delivery.id) return delivery;
    else return d;
  });
}

function setDeliveries(action: IDeliveriesStateSetAction) {
  return action.payload.deliveries;
}

function deliveriesReducer(state: IDelivery[], action: IDeliveriesStateAction) {
  switch (action.type) {
    case ADD_DELIVERY:
      return addDelivery(state, action);

    case CLEAR_DELIVERIES:
      return clearDeliveries();

    case REMOVE_DELIVERY:
      return removeDelivery(state, action);

    case SAVE_DELIVERY:
      return saveDelivery(state, action);

    case SET_DELIVERIES:
      return setDeliveries(action);

    default:
      return state;
  }
}

function useDeliveriesState() {
  const [deliveries, dispatch] = useReducer(
    deliveriesReducer,
    INITIAL_DELIVERIES_STATE
  );

  const addDelivery = useCallback(
    (delivery: IDelivery) => {
      const addDeliveryAction: IDeliveriesStateAddAction = {
        type: ADD_DELIVERY,
        payload: {
          delivery,
        },
      };

      dispatch(addDeliveryAction);
    },
    [dispatch]
  );

  const clearDeliveries = useCallback(() => {
    const clearDeliveriesAction: IDeliveriesStateClearAction = {
      type: CLEAR_DELIVERIES,
    };

    dispatch(clearDeliveriesAction);
  }, [dispatch]);

  const removeDelivery = useCallback(
    (delivery: IDelivery) => {
      const removeDeliveryAction: IDeliveriesStateRemoveAction = {
        type: REMOVE_DELIVERY,
        payload: {
          delivery,
        },
      };

      dispatch(removeDeliveryAction);
    },
    [dispatch]
  );

  const saveDelivery = useCallback(
    (delivery: IDelivery) => {
      const saveDeliveryAction: IDeliveriesStateSaveAction = {
        type: SAVE_DELIVERY,
        payload: {
          delivery,
        },
      };

      dispatch(saveDeliveryAction);
    },
    [dispatch]
  );

  const setDeliveries = useCallback(
    (deliveries: IDelivery[]) => {
      dispatch({ type: SET_DELIVERIES, payload: { deliveries } });
    },
    [dispatch]
  );

  return {
    addDelivery,
    clearDeliveries,
    deliveries,
    removeDelivery,
    saveDelivery,
    setDeliveries,
  };
}

export default useDeliveriesState;
