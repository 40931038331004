import { useCallback } from "react";

import useAsyncState from "../AppShell/hooks/useAsyncState";

import useOrdersStore from "./useOrdersStore";
import useOrdersClient from "./useOrdersClient";

function useAsyncOrdersStore() {
  const { isLoading, setIsLoading } = useAsyncState();
  const ordersClient = useOrdersClient();
  const ordersStore = useOrdersStore();
  const { setOrders } = ordersStore;

  const fetchOrders = useCallback(async () => {
    setIsLoading(true);
    const orders = await ordersClient.fetchOrders();
    setOrders(orders);
    setIsLoading(false);
  }, [ordersClient, setIsLoading, setOrders]);

  return {
    ...ordersStore,
    fetchOrders,
    isLoading,
  };
}

export default useAsyncOrdersStore;
