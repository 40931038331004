import { useCallback, useState } from "react";

const ERROR_NOTIFICATION_TYPE = "error";

interface IErrorNotification {
  id: string;
  message: string;
  type: typeof ERROR_NOTIFICATION_TYPE;
}

const SUCCESS_NOTIFICATION_TYPE = "success";

interface ISuccessNotification {
  id: string;
  message: string;
  type: typeof SUCCESS_NOTIFICATION_TYPE;
}

export type Notification = ISuccessNotification | IErrorNotification;

type NotificationDraft = Omit<Notification, "id">;

function useNotificationState() {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = useCallback(
    (notification: NotificationDraft) => {
      const id = `${notification.type}-${new Date().getTime()}`;
      setNotifications([...notifications, { ...notification, id }]);
    },
    [notifications, setNotifications]
  );

  const addErrorNotification = useCallback(
    (message: string) => {
      addNotification({ message, type: ERROR_NOTIFICATION_TYPE });
    },
    [addNotification]
  );

  const addSuccessNotification = useCallback(
    (message: string) => {
      addNotification({ message, type: SUCCESS_NOTIFICATION_TYPE });
    },
    [addNotification]
  );

  const removeNotification = useCallback(
    (notification: Notification) => {
      setNotifications(notifications.filter((n) => n !== notification));
    },
    [notifications, setNotifications]
  );

  return {
    addSuccessNotification,
    addErrorNotification,
    notifications,
    removeNotification,
  };
}

export default useNotificationState;
