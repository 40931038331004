import React from "react";
import withAsyncStore from "../AppShell/components/withAsyncStore";
import useAsyncState from "../AppShell/hooks/useAsyncState";

import LoadingSpinner from "./LoadingSpinner";

function createWithAsyncStore() {
  return function <
    P extends object,
    H extends () => Pick<ReturnType<typeof useAsyncState>, "isLoading">
  >(Component: React.ComponentType<P>, useAsyncStore: H) {
    return withAsyncStore(Component, LoadingSpinner, useAsyncStore);
  };
}

export default createWithAsyncStore();
