export const LOCAL_STORAGE_KEY = "deliveryOptionsInputData";

class DeliveryOptionsInputDataClient {
  static getData() {
    const deliveryOptionsData = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!deliveryOptionsData) {
      throw new Error("Failed to load delivery Options data");
    }

    return JSON.parse(deliveryOptionsData);
  }

  static saveData(data: any) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
  }
}

export default DeliveryOptionsInputDataClient;
