import set from "date-fns/set";
import TimePicker from "@mui/lab/TimePicker";
import TextField from "@mui/material/TextField";
import { useField } from "formik";
import React, { useCallback, useMemo } from "react";

interface INewDeliveryWindowFormTimePickerProps {
  label: string;
  name: string;
}

function NewDeliveryWindowFormTimePicker(
  props: INewDeliveryWindowFormTimePickerProps
) {
  const field = useField(props.name);
  const fieldHelpers = field[2];
  const fieldMeta = field[1];

  const setTime = useCallback(
    (time) => {
      fieldHelpers.setValue(time);
    },
    [fieldHelpers]
  );

  const renderTimeField = useCallback(
    (params) => <TextField {...params} />,
    []
  );

  const limits = useMemo(() => {
    const now = new Date();

    return {
      max: set(now, { hours: 18, minutes: 59, seconds: 59 }),
      min: set(now, { hours: 10, minutes: 0, seconds: 0 }),
    };
  }, []);

  return (
    <TimePicker
      disableIgnoringDatePartForTimeValidation={false}
      inputFormat="HH:mm"
      label={props.label}
      maxTime={limits.max}
      minTime={limits.min}
      minutesStep={15}
      onChange={setTime}
      renderInput={renderTimeField}
      value={fieldMeta.value}
    />
  );
}

export default NewDeliveryWindowFormTimePicker;
