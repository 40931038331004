import { createContext } from "react";

import DeliveryLocationsClient from "../clients/DeliveryLocationsClient";
import useDeliveryLocationsState from "../hooks/useDeliveryLocationsState";

export const DeliveryLocationsClientContext =
  createContext<DeliveryLocationsClient | null>(null);
export const DeliveryLocationsStateContext = createContext<ReturnType<
  typeof useDeliveryLocationsState
> | null>(null);
