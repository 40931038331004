import gql from "graphql-tag";
import GqlClient from "../AppShell/clients/gqlClient";
import { IDeliveryLocation } from "./DeliveryLocationsClient";
import { IDeliveryWindow } from "./DeliveryWindowsClient";
import { IOrder } from "./OrdersClient";

export interface IDelivery {
  id: string;
  location: IDeliveryLocation;
  orders: IOrder[];
  window: IDeliveryWindow;
}

const submitDeliveriesMutation = gql`
  mutation submitDeliveriesMutation($deliveries: [DeliveryInput!]!) {
    deliveries {
      submitDeliveries(deliveries: $deliveries) {
        deliveries {
          id
          location {
            id
            name
          }
          orders {
            currency
            customer
            id
            phone
            products {
              description
              id
              quantity
              total
            }
          }
        }
      }
    }
  }
`;

class DeliveriesClient {
  static createDelivery(
    location: IDeliveryLocation,
    orders: IOrder[],
    window: IDeliveryWindow
  ): IDelivery {
    return {
      id: `${location.id}-${window.id}`,
      location,
      orders,
      window,
    };
  }

  constructor(private gqlClient: GqlClient) {}

  async submit(deliveries: IDelivery[]) {
    const submitDeliveriesInput = {
      deliveries,
    };
    try {
      await this.gqlClient.sendQuery(
        submitDeliveriesMutation,
        submitDeliveriesInput
      );
    } catch (error) {
      console.error("Failed to submit deliveries.", error);
      throw new Error("Failed to submit deliveries.");
    }
  }
}

export default DeliveriesClient;
