import { useContext } from "react";
import { DeliveryLocationsClientContext } from "../context/deliveryLocations";

function useDeliveryLocationsClient() {
  const deliveryLocationsClient = useContext(DeliveryLocationsClientContext);

  if (deliveryLocationsClient === null) {
    throw new Error(
      "Delivery locations client not found. A context provider must be present in your app."
    );
  }

  return deliveryLocationsClient;
}

export default useDeliveryLocationsClient;
