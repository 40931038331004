import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { useField } from "formik";
import React, { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";

import { IDeliveryWindow } from "../../clients/DeliveryWindowsClient";

import DeliveryWindowSelect from "./DeliveryWindowSelect";

const i18nMessages = defineMessages({
  deliveryWindow: {
    defaultMessage: "Delivery window",
    id: "delivery.order.deliveryWindow",
  },
});

function DeliveryWindowInput() {
  const intl = useIntl();

  const [_, fieldMeta, fieldHelpers] = useField("deliveryWindow");

  const setDeliveryWindow = useCallback(
    (deliveryWindow: IDeliveryWindow | null) => {
      fieldHelpers.setValue(deliveryWindow);
    },
    [fieldHelpers]
  );

  const deliveryWindowInputPrefix = "deliveryWindow";
  const deliveryWindowInputLabelId = `${deliveryWindowInputPrefix}-label`;

  return (
    <FormControl>
      <InputLabel id={deliveryWindowInputLabelId}>
        {intl.formatMessage(i18nMessages.deliveryWindow)}
      </InputLabel>
      <DeliveryWindowSelect
        deliveryWindow={fieldMeta.value}
        onChange={setDeliveryWindow}
      />
    </FormControl>
  );
}

export default DeliveryWindowInput;
