import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";

import useAuthenticationClient from "../../hooks/useAuthenticationClient";

const i18nMessages = defineMessages({
  signingOut: {
    defaultMessage: "Please wait, we are signing you out...",
    id: "app.auth.signingOut",
  },
});

function SignOut() {
  const client = useAuthenticationClient();
  const intl = useIntl();

  useEffect(() => {
    client.signOut();
  }, [client]);

  return <p>{intl.formatMessage(i18nMessages.signingOut)}</p>;
}

export default SignOut;
