import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { defineMessages, useIntl } from "react-intl";

import { IProduct } from "../../clients/OrdersClient";

const i18nMessages = defineMessages({
  quantity: {
    defaultMessage: "Quantity: {quantity}",
    id: "delivery.order.product.quantity",
  },
});

interface IProductListItem {
  product: IProduct;
}

function ProductListItem(props: IProductListItem) {
  const { product } = props;
  const intl = useIntl();

  return (
    <ListItem>
      <ListItemText
        primary={product.description}
        secondary={`${intl.formatMessage(i18nMessages.quantity, {
          quantity: product.quantity,
        })}`}
      />
    </ListItem>
  );
}

export default ProductListItem;
