import { useCallback } from "react";

import useAsyncState from "../AppShell/hooks/useAsyncState";

import useDeliveryWindowsStore from "./useDeliveryWindowsStore";
import useDeliveryWindowsClient from "./useDeliveryWindowsClient";

function useAsyncWindowsDeliveryStore() {
  const { isLoading, setIsLoading } = useAsyncState();
  const deliveryWindowsClient = useDeliveryWindowsClient();
  const deliveryWindowStore = useDeliveryWindowsStore();
  const { setDeliveryWindows } = deliveryWindowStore;

  const fetchDeliveryWindows = useCallback(async () => {
    setIsLoading(true);

    const deliveryWindows = await deliveryWindowsClient.fetchDeliveryWindows();

    setDeliveryWindows(deliveryWindows);

    setIsLoading(false);
  }, [deliveryWindowsClient, setDeliveryWindows, setIsLoading]);

  return {
    ...deliveryWindowStore,
    fetchDeliveryWindows,
    isLoading,
  };
}

export default useAsyncWindowsDeliveryStore;
