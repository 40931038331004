import { FormikHelpers, useFormik } from "formik";
import { useCallback, useMemo } from "react";
import DeliveriesClient from "../clients/DeliveriesClient";
import { IDeliveryLocation } from "../clients/DeliveryLocationsClient";
import { IDeliveryWindow } from "../clients/DeliveryWindowsClient";
import useDeliveriesStore from "./useDeliveriesStore";

interface INewDeliveryFormState {
  deliveryLocation: IDeliveryLocation | null;
  deliveryWindow: IDeliveryWindow | null;
}

function useNewDeliveryForm() {
  const { addDelivery } = useDeliveriesStore();

  const initialValues = useMemo(() => {
    const initialDeliveryFormState: INewDeliveryFormState = {
      deliveryLocation: null,
      deliveryWindow: null,
    };

    return initialDeliveryFormState;
  }, []);

  const onSubmit = useCallback(
    (
      values: INewDeliveryFormState,
      formik: FormikHelpers<INewDeliveryFormState>
    ) => {
      const delivery = DeliveriesClient.createDelivery(
        values.deliveryLocation as IDeliveryLocation,
        [],
        values.deliveryWindow as IDeliveryWindow
      );

      addDelivery(delivery);

      formik.resetForm();
    },
    [addDelivery]
  );

  const formik = useFormik({ initialValues, onSubmit });
  const { deliveryLocation, deliveryWindow } = formik.values;

  const hasAllRequiredValues = useMemo(() => {
    return deliveryLocation && deliveryWindow;
  }, [deliveryLocation, deliveryWindow]);

  return {
    formik,
    hasAllRequiredValues,
    initialValues,
  };
}

export default useNewDeliveryForm;
