import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { PropsWithChildren } from "react";

interface IContainerProps {
  title: string;
}

function Container(props: PropsWithChildren<IContainerProps>) {
  const { children, title } = props;

  return (
    <Box>
      <Typography variant="h3" gutterBottom component="div">
        {title}
      </Typography>
      {children}
    </Box>
  );
}

export default Container;
