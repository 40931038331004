import formatISO from "date-fns/formatISO";

export interface IDeliveryWindow {
  from: Date;
  id: string;
  to: Date;
}

export class DeliveryWindow implements IDeliveryWindow {
  constructor(public from: Date, public id: string, public to: Date) {}

  toJSON() {
    return {
      ...this,
      from: formatISO(this.from),
      to: formatISO(this.to),
    };
  }
}
