import { createContext } from "react";

import DeliveriesClient from "../clients/DeliveriesClient";
import useDeliveriesState from "../hooks/useDeliveriesState";

export const DeliveriesClientContext = createContext<DeliveriesClient | null>(
  null
);
export const DeliveriesStateContext = createContext<ReturnType<
  typeof useDeliveriesState
> | null>(null);
