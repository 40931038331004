import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React, { useCallback } from "react";
import { IDelivery } from "../../clients/DeliveriesClient";
import { IOrder } from "../../clients/OrdersClient";
import useDeliveriesStore from "../../hooks/useDeliveriesStore";
import ExpandableListItem from "../ExpandableListItem";
import DeliveryListItemHeader from "./DeliveryListItemHeader";
import OrderList from "./OrderList";

interface IDeliveryListItemProps {
  delivery: IDelivery;
  isSelected?: boolean;
  onSelect: (delivery: IDelivery | null) => void;
}

function DeliveryListItem(props: IDeliveryListItemProps) {
  const { delivery, isSelected, onSelect } = props;
  const { saveDelivery, removeDelivery } = useDeliveriesStore();

  const handleDeleteButtonClick = useCallback(() => {
    removeDelivery(delivery);
  }, [delivery, removeDelivery]);

  const updateDeliveryOrders = useCallback(
    (selectedOrders: IOrder[]) => {
      saveDelivery({ ...delivery, orders: selectedOrders });
    },
    [delivery, saveDelivery]
  );

  const toggleOrderList = useCallback(
    (isSelected: boolean) => {
      onSelect(isSelected ? delivery : null);
    },
    [delivery, onSelect]
  );

  return (
    <ExpandableListItem
      header={<DeliveryListItemHeader delivery={delivery} />}
      isExpanded={isSelected}
      onToggleContent={toggleOrderList}
      secondaryActions={
        <IconButton
          aria-label="delete"
          edge="end"
          onClick={handleDeleteButtonClick}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <Box sx={{ p: 4 }}>
        <OrderList
          onSelectedOrdersChange={updateDeliveryOrders}
          selectedOrders={delivery.orders}
        />
      </Box>
    </ExpandableListItem>
  );
}

export default DeliveryListItem;
