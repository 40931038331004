import React from "react";
import { useIntl } from "react-intl";
import { Link, Route, Routes } from "react-router-dom";

import NotificationList from "../AppShell/components/notifications/NotificationList";
import ChromeNav from "../AppShell/components/Nav";

import DeliveryManagerInputData from "./delivery/DeliveryManagerInputData";
import DeliveryManager from "./delivery/DeliveryManager";
import DeliveryWindowsScheduler from "./deliveryWindows/DeliveryWindowsScheduler";
import { defineMessage } from "@formatjs/intl";

const i18nMessages = defineMessage({
  deliveryManager: {
    defaultMessage: "Delivery manager",
    id: "delivery.manager",
  },
  deliveryWindows: {
    defaultMessage: "Delivery windows",
    id: "delivery.windows",
  },
});

function Layout() {
  const intl = useIntl();

  return (
    <>
      <ChromeNav>
        <Link to="/logistics/delivery/manager/init">
          {intl.formatMessage(i18nMessages.deliveryManager)}
        </Link>
        {" | "}
        <Link to="/logistics/delivery/windows">
          {intl.formatMessage(i18nMessages.deliveryWindows)}
        </Link>
      </ChromeNav>
      <hr />
      <NotificationList />
      <Routes>
        <Route
          path="/logistics/delivery/manager/init"
          element={<DeliveryManagerInputData />}
        />
        <Route
          path="/logistics/delivery/manager"
          element={<DeliveryManager />}
        />
        <Route
          path="/logistics/delivery/windows"
          element={<DeliveryWindowsScheduler />}
        />
      </Routes>
    </>
  );
}

export default Layout;
