import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import useAuthentication from "../../hooks/useAuthentication";

const i18nMessages = defineMessages({
  authenticationRequired: {
    defaultMessage: "Authentication is required to access this page.",
    id: "app.auth.authenticationRequired",
  },
  authenticationFailed: {
    defaultMessage: "We couldn't verify your identity.",
    id: "app.auth.authenticationFailed",
  },
  signIn: {
    defaultMessage: "Sign in",
    id: "app.auth.signIn",
  },
});

function withAuthentication(Component: React.ComponentType) {
  return function WithAuthenticationHoc(props: React.PropsWithChildren<{}>) {
    const { isAuthenticationRequired, error } = useAuthentication();
    const intl = useIntl();

    if (isAuthenticationRequired) {
      return (
        <p>
          {intl.formatMessage(i18nMessages.authenticationRequired)}{" "}
          <Link to="/auth/signIn">
            {intl.formatMessage(i18nMessages.signIn)}
          </Link>
        </p>
      );
    }

    if (error) {
      return <p>{intl.formatMessage(i18nMessages.authenticationFailed)}</p>;
    }

    return <Component {...props}></Component>;
  };
}

export default withAuthentication;
