import { createContext } from "react";

import AuthClient from "../clients/auth";

interface AuthContextValue {
  client: AuthClient;
}

const AuthContext = createContext<AuthContextValue | null>(null);

export default AuthContext;
