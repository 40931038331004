import CssBaseline from "@mui/material/CssBaseline";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@date-io/date-fns";
import React, { useMemo } from "react";

import "./amplifyConfig";
import GqlClient from "./AppShell/clients/gqlClient";
import Chrome from "./AppShell/components/Chrome";
import DeliveriesClient from "./clients/DeliveriesClient";
import DeliveryLocationsClient from "./clients/DeliveryLocationsClient";
import DeliveryWindowsClient from "./clients/DeliveryWindowsClient";
import OrdersClient from "./clients/OrdersClient";
import Layout from "./components/Layout";
import {
  DeliveriesClientContext,
  DeliveriesStateContext,
} from "./context/deliveries";
import {
  DeliveryLocationsClientContext,
  DeliveryLocationsStateContext,
} from "./context/deliveryLocations";
import { DeliveryWindowsClientContext } from "./context/deliveryWindows";
import useDeliveriesState from "./hooks/useDeliveriesState";
import useDeliveryLocationsState from "./hooks/useDeliveryLocationsState";
import { OrdersClientContext, OrdersStateContext } from "./context/orders";
import useOrdersState from "./hooks/useOrdersState";
import i18nMessages from "./i18n/es-MX.json";

function App() {
  const gqlClient = useMemo(() => new GqlClient(), []);
  const deliveriesClient = useMemo(
    () => new DeliveriesClient(gqlClient),
    [gqlClient]
  );
  const deliveriesState = useDeliveriesState();
  const deliveryLocationsClient = useMemo(
    () => new DeliveryLocationsClient(),
    []
  );
  const deliveryLocationsState = useDeliveryLocationsState();
  const deliveryWindowsClient = useMemo(
    () => new DeliveryWindowsClient(gqlClient),
    [gqlClient]
  );

  const ordersClient = useMemo(() => new OrdersClient(), []);
  const ordersState = useOrdersState();

  return (
    <>
      <CssBaseline />
      <Chrome
        i18n={{ locale: "es-MX", messages: i18nMessages }}
        signInRedirectPath="/logistics/delivery/windows"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DeliveriesClientContext.Provider value={deliveriesClient}>
            <DeliveriesStateContext.Provider value={deliveriesState}>
              <DeliveryLocationsClientContext.Provider
                value={deliveryLocationsClient}
              >
                <DeliveryLocationsStateContext.Provider
                  value={deliveryLocationsState}
                >
                  <DeliveryWindowsClientContext.Provider
                    value={deliveryWindowsClient}
                  >
                    <OrdersClientContext.Provider value={ordersClient}>
                      <OrdersStateContext.Provider value={ordersState}>
                        <Layout />
                      </OrdersStateContext.Provider>
                    </OrdersClientContext.Provider>
                  </DeliveryWindowsClientContext.Provider>
                </DeliveryLocationsStateContext.Provider>
              </DeliveryLocationsClientContext.Provider>
            </DeliveriesStateContext.Provider>
          </DeliveriesClientContext.Provider>
        </LocalizationProvider>
      </Chrome>
    </>
  );
}

export default App;
