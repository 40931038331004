import { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import useNotificationsStore from "../AppShell/hooks/useNotificationsStore";
import { IDelivery } from "../clients/DeliveriesClient";
import useDeliveriesStore from "./useDeliveriesStore";

const i18nMessages = defineMessages({
  deliveriesSent: {
    defaultMessage: "Deliveries have been sent successfully.",
    id: "delivery.manager.deliveries.sent.ok",
  },
  deliveriesSentError: {
    defaultMessage:
      "There was an error while sending deliveries. Please try again later.",
    id: "delivery.manager.deliveries.sent.error",
  },
});

function useDeliveriesForm(deliveries: IDelivery[]) {
  const intl = useIntl();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationsStore();
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const { clearDeliveries, submitDeliveries } = useDeliveriesStore();

  const submitDeliveriesCallback = useCallback(async () => {
    setIsInProgress(true);
    try {
      await submitDeliveries(deliveries);

      addSuccessNotification(intl.formatMessage(i18nMessages.deliveriesSent));

      clearDeliveries();
    } catch (error) {
      addErrorNotification(
        intl.formatMessage(i18nMessages.deliveriesSentError)
      );
    }

    setIsInProgress(false);
  }, [
    addErrorNotification,
    addSuccessNotification,
    clearDeliveries,
    deliveries,
    intl,
    setIsInProgress,
    submitDeliveries,
  ]);

  return { isInProgress, submitDeliveries: submitDeliveriesCallback };
}

export default useDeliveriesForm;
