import Button from "@mui/material/Button";
import React, { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import useDeliveriesForm from "../../hooks/useDeliveriesForm";
import useDeliveriesStore from "../../hooks/useDeliveriesStore";

const i18nMessages = defineMessages({
  submit: { defaultMessage: "Submit", id: "actions.submit" },
});

function SubmitDeliveriesForm() {
  const intl = useIntl();
  const { deliveries } = useDeliveriesStore();
  const { isInProgress, submitDeliveries } = useDeliveriesForm(deliveries);

  const hasDeliveries = deliveries.length > 0;
  const hasValidDeliveries = useMemo(
    () => deliveries.every((dw) => dw.orders.length > 0),
    [deliveries]
  );
  const isButtonDisabled =
    !hasDeliveries || !hasValidDeliveries || isInProgress;

  return (
    <Button
      disabled={isButtonDisabled}
      onClick={submitDeliveries}
      variant="contained"
    >
      {intl.formatMessage(i18nMessages.submit)}
    </Button>
  );
}

export default SubmitDeliveriesForm;
