import React from "react";
import List from "@mui/material/List";

import DeliveryWindowListItem from "./DeliveryWindowListItem";
import useDeliveryWindowsStore from "../../hooks/useDeliveryWindowsStore";

function DeliveryWindowsList() {
  const { deliveryWindows } = useDeliveryWindowsStore();

  return (
    <List>
      {deliveryWindows.map((dw) => (
        <DeliveryWindowListItem key={dw.id} deliveryWindow={dw} />
      ))}
    </List>
  );
}

export default DeliveryWindowsList;
