import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import { IDeliveryWindow } from "../../clients/DeliveryWindowsClient";
import DeliveryWindowListItemRemoveButton from "./DeliveryWindowListItemRemoveButton";
import useDeliveryWindowFormatter from "../../hooks/useDeliveryWindowFormatter";

interface DeliveryWindowListItemProps {
  deliveryWindow: IDeliveryWindow;
}

function DeliveryWindowListItem(props: DeliveryWindowListItemProps) {
  const { deliveryWindow } = props;
  const deliveryWindowFormatter = useDeliveryWindowFormatter();

  const date = deliveryWindowFormatter.formatDate(deliveryWindow);
  const timeWindow = deliveryWindowFormatter.formatTime(deliveryWindow);

  return (
    <ListItem
      divider
      secondaryAction={
        <DeliveryWindowListItemRemoveButton deliveryWindow={deliveryWindow} />
      }
    >
      <ListItemText primary={date} secondary={timeWindow} />
    </ListItem>
  );
}

export default DeliveryWindowListItem;
