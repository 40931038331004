import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";

import useAuthenticationClient from "../../hooks/useAuthenticationClient";

const i18nMessages = defineMessages({
  signingIn: {
    defaultMessage: "Signing in...",
    id: "app.auth.signingIn",
  },
});

function SignIn() {
  const client = useAuthenticationClient();
  const intl = useIntl();

  useEffect(() => {
    client.signIn();
  }, [client]);

  return <p>{intl.formatMessage(i18nMessages.signingIn)}</p>;
}

export default SignIn;
