import List from "@mui/material/List";
import React, { useCallback, useMemo, useState } from "react";

import { IOrder } from "../../clients/OrdersClient";
import useAsyncOrdersStore from "../../hooks/useAsyncOrdersStore";

import OrderListItem from "./OrderListItem";

interface ISelectedOrdersById {
  [orderId: string]: IOrder;
}

interface IOrderListProps {
  onSelectedOrdersChange: (orders: IOrder[]) => void;
  selectedOrders: IOrder[];
}

function OrderList(props: IOrderListProps) {
  const { onSelectedOrdersChange, selectedOrders } = props;
  const { orders } = useAsyncOrdersStore();
  const [focusedOrder, setFocusedOrder] = useState<IOrder | null>(null);

  const selectedOrdersById = useMemo(() => {
    return selectedOrders.reduce(
      (ordersById: ISelectedOrdersById, order: IOrder) => {
        ordersById[order.id] = order;

        return ordersById;
      },
      {}
    );
  }, [selectedOrders]);

  const setSelectedOrders = useCallback(
    (order: IOrder, isOrderSelected: boolean) => {
      let newOrders: IOrder[];
      if (isOrderSelected) {
        newOrders = [...selectedOrders, order];
      } else {
        newOrders = selectedOrders.filter((o) => o.id !== order.id);
      }

      onSelectedOrdersChange(newOrders);
    },
    [onSelectedOrdersChange, selectedOrders]
  );

  const toggleOrderDetails = useCallback(
    (order: IOrder) => {
      setFocusedOrder((prevSelectedOrder) =>
        prevSelectedOrder?.id === order.id ? null : order
      );
    },
    [setFocusedOrder]
  );

  return (
    <List disablePadding>
      {orders.map((order) => (
        <OrderListItem
          isExpanded={focusedOrder?.id === order.id}
          isSelected={!!selectedOrdersById[order.id]}
          key={order.id}
          onSelectedChange={setSelectedOrders}
          onToggleDetails={toggleOrderDetails}
          order={order}
        />
      ))}
    </List>
  );
}

export default OrderList;
