import gql from "graphql-tag";
import GqlClient from "../AppShell/clients/gqlClient";
import { DeliveryWindow, IDeliveryWindow } from "./DeliveryWindow";
export type { IDeliveryWindow } from "./DeliveryWindow";

const getDeliveryWindowsQuery = gql`
  query getDeliveryWindows {
    deliveryWindows {
      from
      id
      to
    }
  }
`;

const submitDeliveryWindowsMutation = gql`
  mutation submitDeliveryWindows($deliveryWindows: [DeliveryWindowInput!]!) {
    deliveryWindows {
      submitDeliveryWindows(deliveryWindows: $deliveryWindows) {
        deliveryWindows {
          from
          id
          to
        }
      }
    }
  }
`;

class DeliveryWindowsClient {
  static createDeliveryWindow(from: Date, to: Date): IDeliveryWindow {
    return new DeliveryWindow(from, `${from.getTime()}-${to.getTime()}`, to);
  }

  constructor(private gqlClient: GqlClient) {}

  async fetchDeliveryWindows() {
    let response: any;

    try {
      response = await this.gqlClient.sendQuery(getDeliveryWindowsQuery);
    } catch (error) {
      console.error("Failed to get delivery windows.", error);
      throw new Error("Failed to get delivery windows.");
    }

    const deliveryWindows: IDeliveryWindow[] = response.deliveryWindows.map(
      (dw: any) => {
        return new DeliveryWindow(
          new Date(Date.parse(dw.from)),
          dw.id,
          new Date(Date.parse(dw.to))
        );
      }
    );

    return deliveryWindows;
  }

  async submit(deliveryWindows: IDeliveryWindow[]) {
    const submitDeliveryWindowsInput = {
      deliveryWindows,
    };
    try {
      await this.gqlClient.sendQuery(
        submitDeliveryWindowsMutation,
        submitDeliveryWindowsInput
      );
    } catch (error) {
      console.error("Failed to submit delivery windows.", error);
      throw new Error("Failed to submit delivery windows.");
    }
  }
}

export default DeliveryWindowsClient;
