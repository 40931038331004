import { useContext } from "react";
import { DeliveryLocationsStateContext } from "../context/deliveryLocations";

function useDeliveryLocationsStore() {
  const deliveryLocationsState = useContext(DeliveryLocationsStateContext);

  if (deliveryLocationsState === null) {
    throw new Error(
      "Delivery locations state not found. A context provider must be present in your app."
    );
  }

  return deliveryLocationsState;
}

export default useDeliveryLocationsStore;
