import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import React from "react";
import { defineMessages, useIntl } from "react-intl";

import { IOrder } from "../../clients/OrdersClient";
import ProductListItem from "./ProductListItem";

const i18nMessages = defineMessages({
  products: { defaultMessage: "Products", id: "delivery.order.products" },
});

interface IProductListProps {
  products: IOrder["products"];
}

function ProductList(props: IProductListProps) {
  const { products } = props;
  const intl = useIntl();

  return (
    <List
      disablePadding
      subheader={
        <ListSubheader>
          {intl.formatMessage(i18nMessages.products)}
        </ListSubheader>
      }
    >
      {products.map((product) => (
        <ProductListItem key={product.id} product={product} />
      ))}
    </List>
  );
}

export default ProductList;
