import { useCallback, useReducer } from "react";
import { IDeliveryLocation } from "../clients/DeliveryLocationsClient";

const INITIAL_DELIVERY_LOCATIONS_STATE: IDeliveryLocation[] = [];

const SAVE_DELIVERY_LOCATION = "deliveryLocations/save";

interface IDeliveryLocationsStateSaveAction {
  type: typeof SAVE_DELIVERY_LOCATION;
  payload: {
    deliveryLocation: IDeliveryLocation;
  };
}

const SET_DELIVERY_LOCATIONS = "deliveryLocations/set";

interface IDeliveryLocationsStateSetAction {
  type: typeof SET_DELIVERY_LOCATIONS;
  payload: {
    deliveryLocations: IDeliveryLocation[];
  };
}

type IDeliveryLocationsStateAction =
  | IDeliveryLocationsStateSaveAction
  | IDeliveryLocationsStateSetAction;

function saveDeliveryLocation(
  state: IDeliveryLocation[],
  action: IDeliveryLocationsStateSaveAction
) {
  const { deliveryLocation } = action.payload;

  return state.map((o) => {
    if (o.id === deliveryLocation.id) return deliveryLocation;
    else return o;
  });
}

function setDeliveryLocations(action: IDeliveryLocationsStateSetAction) {
  return action.payload.deliveryLocations;
}

function deliveryLocationsReducer(
  state: IDeliveryLocation[],
  action: IDeliveryLocationsStateAction
) {
  switch (action.type) {
    case SAVE_DELIVERY_LOCATION:
      return saveDeliveryLocation(state, action);

    case SET_DELIVERY_LOCATIONS:
      return setDeliveryLocations(action);

    default:
      return state;
  }
}

function useDeliveryLocationsState() {
  const [deliveryLocations, dispatch] = useReducer(
    deliveryLocationsReducer,
    INITIAL_DELIVERY_LOCATIONS_STATE
  );

  const saveDeliveryLocation = useCallback(
    (deliveryLocation: IDeliveryLocation) => {
      const saveDeliveryLocationAction: IDeliveryLocationsStateSaveAction = {
        type: SAVE_DELIVERY_LOCATION,
        payload: {
          deliveryLocation,
        },
      };

      dispatch(saveDeliveryLocationAction);
    },
    [dispatch]
  );

  const setDeliveryLocations = useCallback(
    (deliveryLocations: IDeliveryLocation[]) => {
      dispatch({
        type: SET_DELIVERY_LOCATIONS,
        payload: { deliveryLocations },
      });
    },
    [dispatch]
  );

  return {
    deliveryLocations,
    saveDeliveryLocation,
    setDeliveryLocations,
  };
}

export default useDeliveryLocationsState;
