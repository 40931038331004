import React, { PropsWithChildren, useMemo } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AuthClient from "../clients/auth";
import AuthContext from "../context/auth";
import { NotificationsStateContext } from "../context/notifications";
import useNotificationState from "../hooks/useNotificationState";
import i18nMessages from "../i18n/es-MX.json";
import Auth from "./auth/Auth";
import SigningIn from "./auth/SigningIn";

type TProps<I18NM extends object> = PropsWithChildren<{
  i18n: {
    locale: string;
    messages: I18NM;
  };
  signInRedirectPath: string;
}>;

function Chrome<I18NM extends object>(props: TProps<I18NM>) {
  const { children, i18n, signInRedirectPath } = props;

  const initialAuthContextValue = useMemo(() => {
    return {
      client: new AuthClient(),
    };
  }, []);

  const notificationsState = useNotificationState();

  return (
    <IntlProvider
      default-Locale="en-US"
      locale={i18n.locale}
      messages={{ ...i18nMessages, ...i18n.messages }}
    >
      <BrowserRouter>
        <NotificationsStateContext.Provider value={notificationsState}>
          <AuthContext.Provider value={initialAuthContextValue}>
            {children}
            <Routes>
              <Route path="/">
                <Route path="auth/*" element={<Auth />} />
                <Route
                  index
                  element={
                    <SigningIn signInRedirectPath={signInRedirectPath} />
                  }
                />
              </Route>
            </Routes>
          </AuthContext.Provider>
        </NotificationsStateContext.Provider>
      </BrowserRouter>
    </IntlProvider>
  );
}

export default Chrome;
