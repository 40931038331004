import Button from "@mui/material/Button";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import useDeliveryWindowsForm from "../../hooks/useDeliveryWindowsForm";
import useDeliveryWindowsStore from "../../hooks/useDeliveryWindowsStore";

const i18nMessages = defineMessages({
  submit: { defaultMessage: "Submit", id: "actions.submit" },
});

function SubmitDeliveryWindowsForm() {
  const intl = useIntl();
  const { deliveryWindows } = useDeliveryWindowsStore();

  const { isInProgress, submitDeliveryWindows } =
    useDeliveryWindowsForm(deliveryWindows);

  const hasDeliveryWindows = deliveryWindows.length > 0;
  const isButtonDisabled = !hasDeliveryWindows || isInProgress;

  return (
    <Button
      disabled={isButtonDisabled}
      onClick={submitDeliveryWindows}
      variant="contained"
    >
      {intl.formatMessage(i18nMessages.submit)}
    </Button>
  );
}

export default SubmitDeliveryWindowsForm;
