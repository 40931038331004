import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";

const i18nMessages = defineMessages({
  signOut: {
    default: "Sign out",
    id: "app.nav.signOut",
  },
});

function ChromeNav(props: React.PropsWithChildren<{}>) {
  const { children } = props;
  const intl = useIntl();

  return (
    <nav>
      {children}
      {" | "}
      <Link to="/auth/signOut">{intl.formatMessage(i18nMessages.signOut)}</Link>
    </nav>
  );
}

export default ChromeNav;
