import set from "date-fns/set";
import { FormikHelpers } from "formik";
import { useCallback, useMemo } from "react";
import { IDeliveryWindow } from "../clients/DeliveryWindow";
import DeliveryWindowsClient from "../clients/DeliveryWindowsClient";
import useDeliveryWindowsStore from "./useDeliveryWindowsStore";

function useNewDeliveryWindowForm() {
  const { addDeliveryWindow } = useDeliveryWindowsStore();

  const initialValues = useMemo(() => {
    const now = new Date();

    const initialNewDeliveryFormState: IDeliveryWindow = {
      from: set(now, { hours: 10, minutes: 0 }),
      id: "newDeliveryWindow",
      to: set(now, { hours: 18, minutes: 0 }),
    };

    return initialNewDeliveryFormState;
  }, []);

  const handleSubmit = useCallback(
    (values: IDeliveryWindow, formik: FormikHelpers<IDeliveryWindow>) => {
      addDeliveryWindow(
        DeliveryWindowsClient.createDeliveryWindow(values.from, values.to)
      );

      formik.resetForm();
    },
    [addDeliveryWindow]
  );

  return {
    handleSubmit,
    initialValues,
  };
}

export default useNewDeliveryWindowForm;
