import { useContext } from "react";
import AuthContext from "../context/auth";

function useAuthenticationClient() {
  const authContextValue = useContext(AuthContext);

  if (authContextValue === null) {
    throw new Error(
      "Auth context has not being initialized. Is Auth context provided being used?"
    );
  }

  return authContextValue.client;
}

export default useAuthenticationClient;
