import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import React from "react";

import useNotificationsStore from "../../hooks/useNotificationsStore";
import NotificationListItem from "./NotificationListItem";

function NotificationList() {
  const { notifications } = useNotificationsStore();

  return (
    <List dense>
      {notifications.map((notification) => (
        <ListItem dense key={notification.id} sx={{ display: "block" }}>
          <NotificationListItem notification={notification} />
        </ListItem>
      ))}
    </List>
  );
}

export default NotificationList;
