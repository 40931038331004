import React, { useCallback, useEffect, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { User } from "../../hooks/useAuthenticatedUser";

import useAuthenticationClient from "../../hooks/useAuthenticationClient";

const i18nMessages = defineMessages({
  signingIn: {
    defaultMessage: "Please wait, we are verifying your identity...",
    id: "app.auth.signingIn",
  },
});

interface SigningInProps {
  signInRedirectPath: string;
}

function SigningIn(props: SigningInProps) {
  const { signInRedirectPath } = props;

  const client = useAuthenticationClient();
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const hash = location.hash;
  const hasIdToken = useMemo(() => /id_token/.test(hash), [hash]);

  const handleErrorChange = useCallback(
    (error: boolean) => {
      if (error) navigate("/auth/error");
    },
    [navigate]
  );

  const handleUserChange = useCallback(
    (user: User | null) => {
      if (user) navigate(signInRedirectPath);
    },
    [navigate, signInRedirectPath]
  );

  const subscribers = useMemo(() => {
    return {
      "/error": [handleErrorChange],
      "/user": [handleUserChange],
    };
  }, [handleErrorChange, handleUserChange]);

  useEffect(() => {
    client.subscribe(subscribers);

    return () => {
      client.unsubscribe(subscribers);
    };
  }, [client, subscribers]);

  if (!hasIdToken) {
    return <Navigate to={signInRedirectPath} />;
  }

  return <p>{intl.formatMessage(i18nMessages.signingIn)}</p>;
}

export default SigningIn;
