import { useCallback, useReducer } from "react";
import { IDeliveryWindow } from "../clients/DeliveryWindowsClient";

const INITIAL_DELIVERY_WINDOWS_STATE: IDeliveryWindow[] = [];

const ADD_DELIVERY_WINDOW = "deliveryWindows/add";

interface IDeliveryWindowsStateAddAction {
  type: typeof ADD_DELIVERY_WINDOW;
  payload: {
    deliveryWindow: IDeliveryWindow;
  };
}

const CLEAR_DELIVERY_WINDOWS = "deliveryWindows/clear";

interface IDeliveryWindowsStateClearAction {
  type: typeof CLEAR_DELIVERY_WINDOWS;
}

const REMOVE_DELIVERY_WINDOW = "deliveryWindows/delete";

interface IDeliveryWindowsStateRemoveAction {
  type: typeof REMOVE_DELIVERY_WINDOW;
  payload: {
    deliveryWindow: IDeliveryWindow;
  };
}

const SET_DELIVERY_WINDOWS = "deliveryWindows/set";

interface IDeliveryWindowsStateSetAction {
  type: typeof SET_DELIVERY_WINDOWS;
  payload: {
    deliveryWindows: IDeliveryWindow[];
  };
}

type IDeliveryWindowsStateAction =
  | IDeliveryWindowsStateAddAction
  | IDeliveryWindowsStateClearAction
  | IDeliveryWindowsStateRemoveAction
  | IDeliveryWindowsStateSetAction;

function addDeliveryWindow(
  state: IDeliveryWindow[],
  action: IDeliveryWindowsStateAddAction
) {
  const { deliveryWindow } = action.payload;

  const hasDeliveryWindow = state.find((dw) => dw.id === deliveryWindow.id);

  if (hasDeliveryWindow) {
    return state;
  }

  return [...state, deliveryWindow];
}

function clearDeliveryWindows() {
  return INITIAL_DELIVERY_WINDOWS_STATE;
}

function removeDeliveryWindow(
  state: IDeliveryWindow[],
  action: IDeliveryWindowsStateRemoveAction
) {
  const {
    payload: { deliveryWindow },
  } = action;

  return state.filter((dw) => dw.id !== deliveryWindow.id);
}

function setDeliveryWindows(action: IDeliveryWindowsStateSetAction) {
  return action.payload.deliveryWindows;
}

function deliveryWindowsReducer(
  state: IDeliveryWindow[],
  action: IDeliveryWindowsStateAction
) {
  switch (action.type) {
    case ADD_DELIVERY_WINDOW:
      return addDeliveryWindow(state, action);

    case CLEAR_DELIVERY_WINDOWS:
      return clearDeliveryWindows();

    case REMOVE_DELIVERY_WINDOW:
      return removeDeliveryWindow(state, action);

    case SET_DELIVERY_WINDOWS:
      return setDeliveryWindows(action);

    default:
      return state;
  }
}

function useDeliveryWindowsState() {
  const [deliveryWindows, dispatch] = useReducer(
    deliveryWindowsReducer,
    INITIAL_DELIVERY_WINDOWS_STATE
  );

  const addDeliveryWindow = useCallback(
    (deliveryWindow: IDeliveryWindow) => {
      const addDeliveryWindowAction: IDeliveryWindowsStateAddAction = {
        type: ADD_DELIVERY_WINDOW,
        payload: {
          deliveryWindow,
        },
      };

      dispatch(addDeliveryWindowAction);
    },
    [dispatch]
  );

  const clearDeliveryWindows = useCallback(() => {
    dispatch({ type: CLEAR_DELIVERY_WINDOWS });
  }, [dispatch]);

  const removeDeliveryWindow = useCallback(
    (deliveryWindow: IDeliveryWindow) => {
      const removeDeliveryWindowAction: IDeliveryWindowsStateRemoveAction = {
        type: REMOVE_DELIVERY_WINDOW,
        payload: {
          deliveryWindow,
        },
      };

      dispatch(removeDeliveryWindowAction);
    },
    [dispatch]
  );

  const setDeliveryWindows = useCallback(
    (deliveryWindows: IDeliveryWindow[]) => {
      dispatch({ type: SET_DELIVERY_WINDOWS, payload: { deliveryWindows } });
    },
    [dispatch]
  );

  return {
    addDeliveryWindow,
    clearDeliveryWindows,
    deliveryWindows,
    removeDeliveryWindow,
    setDeliveryWindows,
  };
}

export default useDeliveryWindowsState;
