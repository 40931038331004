import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React, { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";
import { IDeliveryLocation } from "../../clients/DeliveryLocationsClient";

import useAsyncDeliveryLocationsStore from "../../hooks/useAsyncDeliveryLocationsStore";

const i18nMessages = defineMessages({
  deliveryLocation: {
    defaultMessage: "Delivery location",
    id: "delivery.order.deliveryLocation",
  },
});

interface IDeliveryLocationSelectProps {
  deliveryLocation: IDeliveryLocation;
  onChange: (deliveryLocation: IDeliveryLocation | null) => void;
}

function DeliveryLocationSelect(props: IDeliveryLocationSelectProps) {
  const { deliveryLocation, onChange } = props;
  const { deliveryLocations } = useAsyncDeliveryLocationsStore();
  const intl = useIntl();

  const emitChangeEvent = useCallback(
    (event: SelectChangeEvent) => {
      const {
        target: { value: deliveryLocationId },
      } = event;

      const deliveryLocation = deliveryLocations.find(
        (dl) => deliveryLocationId === dl.id
      );

      onChange(deliveryLocation || null);
    },
    [deliveryLocations, onChange]
  );

  const deliveryLocationInputPrefix = "deliveryLocation";
  const deliveryLocationInputId = `${deliveryLocationInputPrefix}-input`;
  const deliveryLocationInputLabelId = `${deliveryLocationInputPrefix}-label`;

  return (
    <Select
      labelId={deliveryLocationInputLabelId}
      id={deliveryLocationInputId}
      label={intl.formatMessage(i18nMessages.deliveryLocation)}
      onChange={emitChangeEvent}
      value={deliveryLocation?.id || ""}
    >
      {deliveryLocations.map((dl) => (
        <MenuItem key={dl.id} value={dl.id}>
          <Typography variant="overline" display="block">
            {dl.name}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

export default DeliveryLocationSelect;
