import { useContext } from "react";
import { DeliveryWindowsClientContext } from "../context/deliveryWindows";

function useWindowDeliveryClient() {
  const deliveryWindowsClient = useContext(DeliveryWindowsClientContext);

  if (deliveryWindowsClient === null) {
    throw new Error(
      "Delivery window client not found. A context provider must be present in your app."
    );
  }

  return deliveryWindowsClient;
}

export default useWindowDeliveryClient;
