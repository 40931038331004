import Alert from "@mui/material/Alert";
import React, { useCallback } from "react";

import useNotificationsStore, {
  Notification,
} from "../../hooks/useNotificationsStore";

interface INotificationListItemProps {
  notification: Notification;
}

function useCloseNotificationCallback(notification: Notification) {
  const { removeNotification } = useNotificationsStore();

  const closeNotificationCallback = useCallback(() => {
    removeNotification(notification);
  }, [notification, removeNotification]);

  return closeNotificationCallback;
}

function NotificationListItem(props: INotificationListItemProps) {
  const closeNotificationCallback = useCloseNotificationCallback(
    props.notification
  );

  return (
    <Alert
      onClose={closeNotificationCallback}
      severity={props.notification.type}
      variant="outlined"
    >
      {props.notification.message}
    </Alert>
  );
}

export default NotificationListItem;
