import { useCallback, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import useNotificationsStore from "../AppShell/hooks/useNotificationsStore";
import { IDeliveryWindow } from "../clients/DeliveryWindowsClient";
import useDeliveryWindowsStore from "./useDeliveryWindowsStore";

const i18nMessages = defineMessages({
  deliveryWindowsSent: {
    defaultMessage: "Delivery windows have been sent successfully.",
    id: "delivery.windows.sent.ok",
  },
  deliveryWindowsSentError: {
    defaultMessage:
      "There was an error while sending delivery windows. Please try again later.",
    id: "delivery.windows.sent.error",
  },
});

function useDeliveryWindowsForm(deliveryWindows: IDeliveryWindow[]) {
  const intl = useIntl();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationsStore();
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const { clearDeliveryWindows, submitDeliveryWindows } =
    useDeliveryWindowsStore();

  const submitDeliveryWindowsCallback = useCallback(async () => {
    setIsInProgress(true);
    try {
      await submitDeliveryWindows(deliveryWindows);

      addSuccessNotification(
        intl.formatMessage(i18nMessages.deliveryWindowsSent)
      );

      clearDeliveryWindows();
    } catch (error) {
      addErrorNotification(
        intl.formatMessage(i18nMessages.deliveryWindowsSentError)
      );
    }

    setIsInProgress(false);
  }, [
    addErrorNotification,
    addSuccessNotification,
    clearDeliveryWindows,
    deliveryWindows,
    intl,
    setIsInProgress,
    submitDeliveryWindows,
  ]);

  return { isInProgress, submitDeliveryWindows: submitDeliveryWindowsCallback };
}

export default useDeliveryWindowsForm;
