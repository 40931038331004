import { defineMessages } from "@formatjs/intl";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { Formik } from "formik";
import React from "react";
import { useIntl } from "react-intl";
import Container from "../Container";
import useNewDeliveryWindowForm from "../../hooks/useNewDeliveryWindowForm";
import NewDeliveryWindowFormDatePicker from "./NewDeliveryWindowFormDatePicker";
import NewDeliveryWindowFormTimePicker from "./NewDeliveryWindowFormTimePicker";

const i18nMessages = defineMessages({
  add: {
    defaultMessage: "Add",
    id: "actions.add",
  },
  from: {
    defaultMessage: "From",
    id: "delivery.windows.from",
  },
  newDeliveryWindow: {
    defaultMessage: "New delivery window",
    id: "delivery.windows.new",
  },
  to: {
    defaultMessage: "To",
    id: "delivery.windows.to",
  },
});

function NewDeliveryWindowForm() {
  const intl = useIntl();
  const { handleSubmit, initialValues } = useNewDeliveryWindowForm();

  return (
    <Container title={intl.formatMessage(i18nMessages.newDeliveryWindow)}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <NewDeliveryWindowFormDatePicker />
            <NewDeliveryWindowFormTimePicker
              label={intl.formatMessage(i18nMessages.from)}
              name="from"
            />
            <NewDeliveryWindowFormTimePicker
              label={intl.formatMessage(i18nMessages.to)}
              name="to"
            />
            <Button
              component="button"
              startIcon={<AddIcon />}
              type="submit"
              variant="text"
            >
              {intl.formatMessage(i18nMessages.add)}
            </Button>
          </form>
        )}
      </Formik>
    </Container>
  );
}

export default NewDeliveryWindowForm;
