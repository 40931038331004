import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import React, { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";

import { IDeliveryWindow } from "../../clients/DeliveryWindowsClient";
import useAsyncDeliveryWindowsStore from "../../hooks/useAsyncDeliveryWindowsStore";
import useDeliveryWindowFormatter from "../../hooks/useDeliveryWindowFormatter";
import withAsyncStore from "../withAsyncStore";

const i18nMessages = defineMessages({
  deliveryWindow: {
    defaultMessage: "Delivery window",
    id: "delivery.order.deliveryWindow",
  },
});

interface IDeliveryWindowSelectProps {
  deliveryWindow: IDeliveryWindow;
  onChange: (deliveryWindow: IDeliveryWindow | null) => void;
}

function DeliveryWindowSelect(props: IDeliveryWindowSelectProps) {
  const { deliveryWindow, onChange } = props;
  const { deliveryWindows } = useAsyncDeliveryWindowsStore();
  const deliveryWindowFormatter = useDeliveryWindowFormatter();
  const intl = useIntl();

  const setDeliveryWindow = useCallback(
    (event: SelectChangeEvent) => {
      const {
        target: { value: deliveryWindowId },
      } = event;

      const deliveryWindow = deliveryWindows.find(
        (dm) => deliveryWindowId === dm.id
      );

      onChange(deliveryWindow || null);
    },
    [deliveryWindows, onChange]
  );

  const deliveryWindowInputPrefix = "deliveryWindow";
  const deliveryWindowInputId = `${deliveryWindowInputPrefix}-input`;
  const deliveryWindowInputLabelId = `${deliveryWindowInputPrefix}-label`;

  return (
    <Select
      id={deliveryWindowInputId}
      label={intl.formatMessage(i18nMessages.deliveryWindow)}
      labelId={deliveryWindowInputLabelId}
      onChange={setDeliveryWindow}
      value={deliveryWindow?.id || ""}
    >
      {deliveryWindows.map((dw) => (
        <MenuItem key={dw.id} value={dw.id}>
          <Typography variant="overline" display="block">
            {deliveryWindowFormatter.formatDate(dw)}
          </Typography>
          <Typography variant="caption" display="block">
            {deliveryWindowFormatter.formatTime(dw)}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );
}

export default withAsyncStore(
  DeliveryWindowSelect,
  useAsyncDeliveryWindowsStore
);
