import React, { useCallback } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";

import { IDeliveryWindow } from "../../clients/DeliveryWindowsClient";
import useDeliveryWindowsStore from "../../hooks/useDeliveryWindowsStore";

interface DeliveryWindowListItemProps {
  deliveryWindow: IDeliveryWindow;
}

function DeliveryWindowListItemRemoveButton(
  props: DeliveryWindowListItemProps
) {
  const { deliveryWindow } = props;

  const { removeDeliveryWindow } = useDeliveryWindowsStore();

  const handleClick = useCallback(() => {
    removeDeliveryWindow(deliveryWindow);
  }, [removeDeliveryWindow, deliveryWindow]);

  return (
    <ListItemButton onClick={handleClick}>
      <ListItemIcon>
        <DeleteIcon />
      </ListItemIcon>
    </ListItemButton>
  );
}

export default DeliveryWindowListItemRemoveButton;
