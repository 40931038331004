import { useContext } from "react";
import { OrdersClientContext } from "../context/orders";

function useOrdersClient() {
  const ordersClient = useContext(OrdersClientContext);

  if (ordersClient === null) {
    throw new Error(
      "Orders client not found. A context provider must be present in your app."
    );
  }

  return ordersClient;
}

export default useOrdersClient;
