import React from "react";
import withAuthentication from "../../AppShell/components/auth/withAuthentication";
import { DeliveryWindowsStateContext } from "../../context/deliveryWindows";
import useDeliveryWindowsState from "../../hooks/useDeliveryWindowsState";

import DeliveryWindows from "./DeliveryWindows";
import NewDeliveryWindowForm from "./NewDeliveryWindowForm";
import SubmitDeliveryWindowsForm from "./SubmitDeliveryWindowsForm";

function DeliveryScheduler() {
  const deliveryWindowsState = useDeliveryWindowsState();

  return (
    <DeliveryWindowsStateContext.Provider value={deliveryWindowsState}>
      <NewDeliveryWindowForm />
      <DeliveryWindows />
      <SubmitDeliveryWindowsForm />
    </DeliveryWindowsStateContext.Provider>
  );
}

export default withAuthentication(DeliveryScheduler);
