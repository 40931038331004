import React from "react";
import { defineMessages, useIntl } from "react-intl";

const i18nMessages = defineMessages({
  loading: {
    defaultMessage: "Loading...",
    id: "loading",
  },
});

function LoadingSpinner() {
  const intl = useIntl();

  return <span>{intl.formatMessage(i18nMessages.loading)}</span>;
}

export default LoadingSpinner;
