import React from "react";
import { defineMessages, useIntl } from "react-intl";

import useDeliveryWindowsStore from "../../hooks/useDeliveryWindowsStore";
import Container from "../Container";

import DeliveryWindowsList from "./DeliveryWindowList";

const i18nMessages = defineMessages({
  deliveryWindows: {
    defaultMessage: "Delivery windows",
    id: "delivery.windows",
  },
  noDeliveryWindows: {
    defaultMessage: "Create your first delivery window using the form above.",
    id: "delivery.windows.empty",
  },
});

function DeliveryWindows() {
  const { deliveryWindows } = useDeliveryWindowsStore();
  const intl = useIntl();

  const hasDeliveryWindows = deliveryWindows.length > 0;

  return (
    <Container title={intl.formatMessage(i18nMessages.deliveryWindows)}>
      {hasDeliveryWindows && <DeliveryWindowsList />}
      {!hasDeliveryWindows && (
        <p>{intl.formatMessage(i18nMessages.noDeliveryWindows)}</p>
      )}
    </Container>
  );
}

export default DeliveryWindows;
