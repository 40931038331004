import React from "react";
import { Route, Routes } from "react-router-dom";

import SignIn from "./SignIn";
import SignOut from "./SignOut";

function Auth() {
  return (
    <Routes>
      <Route path="signIn" element={<SignIn />} />
      <Route path="signOut" element={<SignOut />} />
    </Routes>
  );
}

export default Auth;
