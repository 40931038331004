import List from "@mui/material/List";
import React, { useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { IDelivery } from "../../clients/DeliveriesClient";
import useAsyncOrdersStore from "../../hooks/useAsyncOrdersStore";
import useDeliveriesStore from "../../hooks/useDeliveriesStore";
import Container from "../Container";
import DeliveryListItem from "./DelvieryListItem";

const i18nMessages = defineMessages({
  deliveries: {
    defaultMessage: "Deliveries",
    id: "delivery.manager.deliveries",
  },
  noDeliveries: {
    defaultMessage: "Create your first delivery using the form above.",
    id: "delivery.manager.empty",
  },
});

function DeliveryList() {
  const { deliveries } = useDeliveriesStore();
  const intl = useIntl();
  const [selectedDelivery, setSelectedDelivery] = useState<IDelivery | null>(
    null
  );

  const { fetchOrders } = useAsyncOrdersStore();

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const hasDeliveries = deliveries.length > 0;

  return (
    <Container title={intl.formatMessage(i18nMessages.deliveries)}>
      <List>
        {!hasDeliveries && (
          <p>{intl.formatMessage(i18nMessages.noDeliveries)}</p>
        )}
        {deliveries.map((delivery) => (
          <DeliveryListItem
            delivery={delivery}
            key={delivery.id}
            onSelect={setSelectedDelivery}
            isSelected={delivery.id === selectedDelivery?.id}
          />
        ))}
      </List>
    </Container>
  );
}

export default DeliveryList;
