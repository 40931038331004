import { useContext } from "react";

import { NotificationsStateContext } from "../context/notifications";
export { type Notification } from "../hooks/useNotificationState";

function useNotificationsStore() {
  const notificationsState = useContext(NotificationsStateContext);

  if (notificationsState === null) {
    throw new Error("Notifications state has not been initialized.");
  }

  return notificationsState;
}

export default useNotificationsStore;
