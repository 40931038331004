import DeliveryOptionsInputDataClient from "./DeliveryOptionsInputDataClient";

export interface IDeliveryLocation {
  id: string;
  name: string;
}

class DeliveryLocationsClient {
  async fetchDeliveryLocations() {
    const inputData = DeliveryOptionsInputDataClient.getData();
    const locations: IDeliveryLocation[] = inputData.locations;

    return Promise.resolve(locations);
  }
}

export default DeliveryLocationsClient;
