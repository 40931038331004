import React from "react";
import withAuthentication from "../../AppShell/components/auth/withAuthentication";
import { DeliveryWindowsStateContext } from "../../context/deliveryWindows";
import useDeliveryWindowsState from "../../hooks/useDeliveryWindowsState";
import NewDeliveryForm from "./NewDeliveryForm";
import DeliveryList from "./DeliveryList";
import SubmitDeliveriesForm from "./SubmitDeliveriesForm";

function DeliveryManager() {
  const deliveryWindowsState = useDeliveryWindowsState();

  return (
    <DeliveryWindowsStateContext.Provider value={deliveryWindowsState}>
      <NewDeliveryForm />
      <DeliveryList />
      <SubmitDeliveriesForm />
    </DeliveryWindowsStateContext.Provider>
  );
}

export default withAuthentication(DeliveryManager);
