import { useContext } from "react";
import { DeliveriesClientContext } from "../context/deliveries";

function useDeliveriesClient() {
  const deliveriesClient = useContext(DeliveriesClientContext);

  if (deliveriesClient === null) {
    throw new Error(
      "Deliveries client not found. A context provider must be present in your app."
    );
  }

  return deliveriesClient;
}

export default useDeliveriesClient;
