import DeliveryOptionsInputDataClient from "./DeliveryOptionsInputDataClient";

export interface IProduct {
  description: string;
  id: string;
  quantity: number;
  total: number;
}

export interface IOrder {
  currency: string;
  customer: string;
  id: string;
  phone: string;
  products: IProduct[];
}

class OrdersClient {
  async fetchOrders() {
    const inputData = DeliveryOptionsInputDataClient.getData();
    const orders: IOrder[] = inputData.orders;

    return Promise.resolve(orders);
  }
}

export default OrdersClient;
