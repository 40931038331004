import { useContext } from "react";
import { OrdersStateContext } from "../context/orders";

function useOrdersStore() {
  const ordersState = useContext(OrdersStateContext);

  if (ordersState === null) {
    throw new Error(
      "Orders state not found. A context provider must be present in your app."
    );
  }

  return ordersState;
}

export default useOrdersStore;
