import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useMemo } from "react";
import { IDelivery } from "../../clients/DeliveriesClient";
import useDeliveryWindowFormatter from "../../hooks/useDeliveryWindowFormatter";

interface DeliveryListItemHeaderProps {
  delivery: IDelivery;
}

function useFormattedDelivery(delivery: IDelivery) {
  const deliveryWindowFormatter = useDeliveryWindowFormatter();

  const formattedDelivery = useMemo(() => {
    const deliveryWindowDate = deliveryWindowFormatter.formatDate(
      delivery.window
    );
    const deliveryWindowTime = deliveryWindowFormatter.formatTime(
      delivery.window
    );
    const deliveryLocation = delivery.location.name;

    return `${deliveryWindowDate} - ${deliveryWindowTime}: ${deliveryLocation}`;
  }, [delivery, deliveryWindowFormatter]);

  return formattedDelivery;
}

function DeliveryListItemHeader(props: DeliveryListItemHeaderProps) {
  const { delivery } = props;

  const formattedDelivery = useFormattedDelivery(delivery);

  return (
    <>
      <ListItemIcon>
        {delivery.orders.length > 0 ? (
          <LocalShippingIcon />
        ) : (
          <ErrorOutlineIcon color="error" />
        )}
      </ListItemIcon>
      <ListItemText primary={formattedDelivery} />
    </>
  );
}

export default DeliveryListItemHeader;
