import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useCallback, useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import { IOrder } from "../../clients/OrdersClient";
import ExpandableListItem from "../ExpandableListItem";
import ProductList from "./ProductList";

const i18nMessages = defineMessages({
  phone: {
    defaultMessage: "Phone: {phone}",
    id: "delivery.order.phone",
  },
  total: {
    defaultMessage: "Total: {total}",
    id: "delivery.order.products.total",
  },
});

interface IOrderListItemProps {
  isExpanded?: boolean;
  isSelected?: boolean;
  onSelectedChange: (order: IOrder, isSelected: boolean) => void;
  onToggleDetails: (order: IOrder) => void;
  order: IOrder;
}

function OrderListItem(props: IOrderListItemProps) {
  const {
    isExpanded = false,
    isSelected = false,
    onSelectedChange,
    onToggleDetails,
    order,
  } = props;
  const intl = useIntl();

  const { currency, products } = order;

  const handleSelectChange = useCallback(() => {
    onSelectedChange(order, !isSelected);
  }, [isSelected, onSelectedChange, order]);

  const handleToggleProducts = useCallback(() => {
    onToggleDetails(order);
  }, [onToggleDetails, order]);

  const formattedTotal = useMemo(() => {
    const total = products.reduce((total, product) => total + product.total, 0);

    return intl.formatNumber(total, { style: "currency", currency });
  }, [currency, intl, products]);

  const labelId = `order-checkbox-${order.id}`;

  return (
    <ExpandableListItem
      header={
        <ListItemText
          id={labelId}
          primary={order.customer}
          secondary={
            <ul>
              <li>
                {intl.formatMessage(i18nMessages.phone, {
                  phone: order.phone,
                })}
              </li>
              <li>
                {intl.formatMessage(i18nMessages.total, {
                  total: formattedTotal,
                })}
              </li>
            </ul>
          }
        />
      }
      isExpanded={isExpanded}
      onToggleContent={handleToggleProducts}
      primaryActions={
        <ListItemIcon>
          <Checkbox
            checked={isSelected}
            disableRipple
            edge="start"
            inputProps={{ "aria-labelledby": `order-${order.id}` }}
            onChange={handleSelectChange}
            tabIndex={-1}
          />
        </ListItemIcon>
      }
    >
      <Box sx={{ pl: 8 }}>
        <ProductList products={products} />
      </Box>
    </ExpandableListItem>
  );
}

export default OrderListItem;
