import { useCallback, useReducer } from "react";
import { IOrder } from "../clients/OrdersClient";

const INITIAL_ORDERS_STATE: IOrder[] = [];

const SAVE_ORDER = "orders/save";

interface IOrdersStateSaveAction {
  type: typeof SAVE_ORDER;
  payload: {
    order: IOrder;
  };
}

const SET_ORDERS = "orders/set";

interface IOrdersStateSetAction {
  type: typeof SET_ORDERS;
  payload: {
    orders: IOrder[];
  };
}

type IOrdersStateAction = IOrdersStateSaveAction | IOrdersStateSetAction;

function saveOrder(state: IOrder[], action: IOrdersStateSaveAction) {
  const { order } = action.payload;

  return state.map((o) => {
    if (o.id === order.id) return order;
    else return o;
  });
}

function setOrders(action: IOrdersStateSetAction) {
  return action.payload.orders;
}

function ordersReducer(state: IOrder[], action: IOrdersStateAction) {
  switch (action.type) {
    case SAVE_ORDER:
      return saveOrder(state, action);

    case SET_ORDERS:
      return setOrders(action);

    default:
      return state;
  }
}

function useOrdersState() {
  const [orders, dispatch] = useReducer(ordersReducer, INITIAL_ORDERS_STATE);

  const saveOrder = useCallback(
    (order: IOrder) => {
      const saveOrderAction: IOrdersStateSaveAction = {
        type: SAVE_ORDER,
        payload: {
          order,
        },
      };

      dispatch(saveOrderAction);
    },
    [dispatch]
  );

  const setOrders = useCallback(
    (orders: IOrder[]) => {
      dispatch({ type: SET_ORDERS, payload: { orders } });
    },
    [dispatch]
  );

  return {
    orders,
    saveOrder,
    setOrders,
  };
}

export default useOrdersState;
