import DatePicker from "@mui/lab/DatePicker";
import TextField from "@mui/material/TextField";
import getDate from "date-fns/getDate";
import getMonth from "date-fns/getMonth";
import getYear from "date-fns/getYear";
import set from "date-fns/set";
import { useField } from "formik";
import React, { useCallback } from "react";
import { defineMessages, useIntl } from "react-intl";

const i18nMessages = defineMessages({
  date: {
    defaultMessage: "Date",
    id: "delivery.windows.date",
  },
});

function NewDeliveryWindowFormDatePicker() {
  const intl = useIntl();

  const fromField = useField("from");
  const fromFieldHelpers = fromField[2];
  const fromFieldMeta = fromField[1];

  const toField = useField("to");
  const toFieldHelpers = toField[2];
  const toFieldMeta = toField[1];

  const setDate = useCallback(
    (date) => {
      fromFieldHelpers.setValue(date);

      const toDate = set(toFieldMeta.value, {
        date: getDate(date),
        month: getMonth(date),
        year: getYear(date),
      });
      toFieldHelpers.setValue(toDate);
    },
    [fromFieldHelpers, toFieldHelpers, toFieldMeta]
  );

  const renderDateField = useCallback(
    (params) => <TextField {...params} />,
    []
  );

  return (
    <DatePicker
      label={intl.formatMessage(i18nMessages.date)}
      minDate={fromFieldMeta.initialValue}
      onChange={setDate}
      renderInput={renderDateField}
      value={fromFieldMeta.value}
    />
  );
}

export default NewDeliveryWindowFormDatePicker;
