import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import React, { PropsWithChildren, ReactElement, useCallback } from "react";

interface IExpandableListItemProps {
  header: ReactElement;
  isExpanded?: boolean;
  onToggleContent: (isSelected: boolean) => void;
  primaryActions?: ReactElement;
  secondaryActions?: ReactElement;
}

function ExpandableListItem(
  props: PropsWithChildren<IExpandableListItemProps>
) {
  const {
    children,
    header,
    isExpanded,
    onToggleContent,
    primaryActions,
    secondaryActions,
  } = props;

  const handleToggleContent = useCallback(() => {
    onToggleContent(!isExpanded);
  }, [onToggleContent, isExpanded]);

  return (
    <>
      <ListItem disablePadding divider secondaryAction={secondaryActions}>
        {primaryActions}
        <ListItemButton onClick={handleToggleContent} selected={isExpanded}>
          {header}
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={isExpanded}>{children}</Collapse>
    </>
  );
}

export default ExpandableListItem;
