import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { FormikContext } from "formik";
import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import useAsyncDeliveryLocationsStore from "../../hooks/useAsyncDeliveryLocationsStore";
import useAsyncDeliveryWindowsStore from "../../hooks/useAsyncDeliveryWindowsStore";
import useNewDeliveryForm from "../../hooks/useNewDeliveryForm";
import Container from "../Container";
import DeliveryLocationInput from "./DeliveryLocationInput";
import DeliveryWindowInput from "./DeliveryWindowInput";

const i18nMessages = defineMessages({
  add: {
    defaultMessage: "Add",
    id: "delivery.manager.add",
  },
  newDelivery: {
    defaultMessage: "New delivery",
    id: "delivery.manager.new",
  },
});

function NewDeliveryForm() {
  const intl = useIntl();

  const { formik, hasAllRequiredValues } = useNewDeliveryForm();

  const { fetchDeliveryLocations } = useAsyncDeliveryLocationsStore();
  const { fetchDeliveryWindows } = useAsyncDeliveryWindowsStore();

  useEffect(() => {
    fetchDeliveryLocations();
    fetchDeliveryWindows();
  }, [fetchDeliveryLocations, fetchDeliveryWindows]);

  return (
    <Container title={intl.formatMessage(i18nMessages.newDelivery)}>
      <FormikContext.Provider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <DeliveryWindowInput />
          <DeliveryLocationInput />
          <Button
            component="button"
            disabled={!hasAllRequiredValues}
            startIcon={<AddIcon />}
            type="submit"
            variant="text"
          >
            {intl.formatMessage(i18nMessages.add)}
          </Button>
        </form>
      </FormikContext.Provider>
    </Container>
  );
}

export default NewDeliveryForm;
