import React, { useCallback, useState } from "react";
import { Importer, ImporterField } from "react-csv-importer";
import { useNavigate } from "react-router-dom";
import "react-csv-importer/dist/index.css";
import { v4 as uuid } from "uuid";
import withAuthentication from "../../AppShell/components/auth/withAuthentication";
import DeliveryOptionsInputDataClient from "../../clients/DeliveryOptionsInputDataClient";
import { IOrder } from "../../clients/OrdersClient";

interface ICsvProduct {
  id: string;
  customer: string;
  quantity: string;
  description: string;
  total: string;
  status: string;
  phone: string;
}

type TOrdersMap = { [key: string]: IOrder };

function DeliveryOptionsDataManager() {
  const [step, setStep] = useState(1);
  const [orders, setOrders] = useState<TOrdersMap>({});
  const [locations, setLocations] = useState<string>(`Ciudadela
Plaza del sol
Punto sur
Galerias
`);
  const navigate = useNavigate();

  const processOrdersChunk = useCallback(
    (csvProducts) => {
      csvProducts
        .filter((csvProduct: ICsvProduct) => !!csvProduct.id)
        .forEach((csvProduct: ICsvProduct) => {
          setOrders((orders: TOrdersMap) => {
            const currentOrder = orders[csvProduct.id];

            return {
              ...orders,
              [csvProduct.id]: {
                currency: "MXN",
                customer: currentOrder?.customer || csvProduct.customer,
                id: currentOrder?.id || csvProduct.id,
                phone: currentOrder?.phone || csvProduct.phone,
                products: [
                  ...(currentOrder?.products || []),
                  {
                    description: csvProduct.description,
                    id: uuid(),
                    quantity: parseInt(csvProduct.quantity) || 1,
                    total:
                      parseFloat(csvProduct.total.replace(/,|\$/g, "")) || 0,
                  },
                ],
              },
            };
          });
        });
    },
    [setOrders]
  );

  const completeStep = useCallback(async () => {
    setStep(step + 1);
  }, [setStep, step]);

  const updateLocations = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const {
        target: { value },
      } = e;

      setLocations(value);
    },
    [setLocations]
  );

  const saveInputData = useCallback(() => {
    DeliveryOptionsInputDataClient.saveData({
      orders: Object.values(orders),
      locations: locations
        .split("\n")
        .filter((l) => !!l)
        .map((l) => {
          return {
            id: uuid(),
            name: l,
          };
        }),
    });

    navigate("/logistics/delivery/manager", { replace: true });
  }, [locations, navigate, orders]);

  return (
    <>
      {step === 1 && (
        <>
          <h1>Ordenes</h1>
          <Importer
            delimiter=","
            processChunk={processOrdersChunk}
            onComplete={completeStep}
          >
            <ImporterField name="id" label="Id" />
            <ImporterField name="customer" label="Customer" />
            <ImporterField name="quantity" label="Quantity" />
            <ImporterField name="description" label="Description" />
            <ImporterField name="anticipo" label="Anticipo" />
            <ImporterField name="total" label="Total" />
            <ImporterField name="status" label="Status" />
            <ImporterField name="phone" label="Phone" />
          </Importer>
        </>
      )}
      {step === 2 && (
        <>
          <h1> Ubicaciones</h1>
          <textarea onChange={updateLocations} value={locations}></textarea>
          <button onClick={saveInputData}>Save input data</button>
        </>
      )}
    </>
  );
}

export default withAuthentication(DeliveryOptionsDataManager);
