import React from "react";

import useAsyncState from "../hooks/useAsyncState";

function withAsyncStore<
  P extends object,
  H extends () => Pick<ReturnType<typeof useAsyncState>, "isLoading">
>(
  Component: React.ComponentType<P>,
  LoadingComponent: React.ComponentType,
  useAsyncStore: H
) {
  function WithAsyncStoreHoc(props: P) {
    const { isLoading } = useAsyncStore();

    if (isLoading) {
      return <LoadingComponent />;
    }

    return <Component {...props} />;
  }

  return WithAsyncStoreHoc;
}

export default withAsyncStore;
