import { Auth, API } from "aws-amplify";
import gql from "graphql-tag";

type TGqlExpression = ReturnType<typeof gql>;

class GqlClient {
  async getAuthToken() {
    const {
      signInUserSession: {
        accessToken: { jwtToken },
      },
    } = await Auth.currentAuthenticatedUser();

    return jwtToken;
  }

  async sendQuery<V extends Object, T>(
    query: TGqlExpression,
    variables: V = Object.create(null)
  ): Promise<T> {
    try {
      const gqlResult: any = await API.graphql({
        authToken: await this.getAuthToken(),
        query: query,
        variables,
      });

      return gqlResult.data;
    } catch (error) {
      console.error("Failed to send GQL query.", error);

      throw error;
    }
  }
}

export default GqlClient;
