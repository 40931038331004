import { useState } from "react";

function useAsyncState() {
  const [isLoading, setIsLoading] = useState<boolean | null>(null);

  return {
    isLoading,
    setIsLoading,
  };
}

export default useAsyncState;
