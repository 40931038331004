import { Auth, Hub } from "aws-amplify";
import { User } from "../hooks/useAuthenticatedUser";

const ERROR_TOPIC_NAME = "/error";
const USER_TOPIC_NAME = "/user";

interface ISignInSubscribers {
  [ERROR_TOPIC_NAME]: Array<(error: boolean) => void>;
  [USER_TOPIC_NAME]: Array<(user: User | null) => void>;
}

type Subscribers = ISignInSubscribers;

class AuthClient {
  private error: boolean = false;
  private isSubscribedToHub: boolean = false;
  private subscribers: Subscribers = {
    [ERROR_TOPIC_NAME]: [],
    [USER_TOPIC_NAME]: [],
  };
  private user: User | null = null;

  getError() {
    return this.error;
  }

  getUser() {
    return this.user;
  }

  subscribe(subscribers: Subscribers) {
    const topicNames = Object.keys(subscribers) as Array<keyof Subscribers>;

    topicNames.forEach((topic) => {
      const currentSubscribers = this.subscribers[topic];
      const newSubscribers = subscribers[topic];

      this.subscribers[topic] = [
        ...currentSubscribers,
        ...newSubscribers,
      ] as any;
    });

    if (!this.isSubscribedToHub) {
      this.isSubscribedToHub = true;
      this.subscribeToHub();
    }
  }

  unsubscribe(subscribers: Subscribers) {
    const topicNames = Object.keys(subscribers) as Array<keyof Subscribers>;

    topicNames.forEach((topic) => {
      const subscribersToRemove = subscribers[topic];

      subscribersToRemove.forEach((callback) => {
        const currentSubscribers = this.subscribers[topic] as any[];

        this.subscribers[topic] = currentSubscribers.filter(
          (cb: any) => cb !== callback
        );
      });
    });
  }

  async signIn() {
    try {
      await Auth.federatedSignIn();
    } catch (error) {
      throw new Error("Failed to initiate cognito federated sign in.");
    }
  }

  async signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      throw new Error("Failed to sign out from cognito.");
    }
  }

  private getSubscribersForTopicName<T extends keyof Subscribers>(
    topicName: T
  ) {
    return this.subscribers[topicName];
  }

  private handleSignInFailureMessage() {
    this.setError(true);
    this.setUser(null);
  }

  private async handleSignInMessage() {
    await this.syncCognitoUser();

    this.setError(!this.getUser());
  }

  private handleSignOutMessage() {
    this.setError(false);
    this.setUser(null);
  }

  private setError(error: typeof this.error) {
    const hasErrorChanged = this.error !== error;

    this.error = error;

    if (hasErrorChanged) {
      const errorSubscribers =
        this.getSubscribersForTopicName(ERROR_TOPIC_NAME);

      errorSubscribers.forEach((errorSubscriber) => {
        errorSubscriber(this.error);
      });
    }
  }

  private setUser(user: typeof this.user) {
    const hasUserChanged = this.user !== user;

    this.user = user;

    if (hasUserChanged) {
      const userSubscribers = this.getSubscribersForTopicName(USER_TOPIC_NAME);

      userSubscribers.forEach((userSubscriber) => {
        userSubscriber(this.user);
      });
    }
  }

  private async subscribeToHub() {
    Hub.listen("auth", (message) => {
      const {
        payload: { event },
      } = message;

      switch (event) {
        case "cognitoHostedUI":
        case "signIn":
          this.handleSignInMessage();
          break;
        case "cognitoHostedUI_failure":
        case "signIn_failure":
          this.handleSignInFailureMessage();
          break;
        case "signOut":
          this.handleSignOutMessage();
          break;
        default:
          console.log(event, message.payload);
      }
    });

    await this.syncCognitoUser();
  }

  private async syncCognitoUser() {
    let authenticatedUser;

    try {
      authenticatedUser = await Auth.currentAuthenticatedUser();
    } catch (error) {
      authenticatedUser = null;
    }

    if (authenticatedUser?.attributes?.email) {
      this.setUser({
        email: authenticatedUser.attributes.email,
      });
    }
  }
}

export default AuthClient;
