import Amplify from "aws-amplify";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function formatAuthRedirectUrl() {
  return isLocalhost
    ? "http://localhost:3000"
    : "https://gustitos.elchinillo.dev";
}

Amplify.configure({
  aws_project_region: "us-west-2",
  API: {
    authenticationType: "AMAZON_COGNITO_USER_POOLS",
    graphql_endpoint:
      "https://cwb7okfr6ndpteerfwh27mdpci.appsync-api.us-west-2.amazonaws.com/graphql",
  },
  Auth: {
    userPoolId: "us-west-2_AoAUZsAiX",
    userPoolWebClientId: "sv4kospm6v2mlp4hlvl6ufjl8",
    oauth: {
      clientId: "sv4kospm6v2mlp4hlvl6ufjl8",
      domain: "auth.gustitos.elchinillo.dev",
      redirectSignIn: formatAuthRedirectUrl(),
      redirectSignOut: formatAuthRedirectUrl(),
      responseType: "token",
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    },
  },
});
